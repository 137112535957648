import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";

const buildTarget = process.env.REACT_APP_BUILD_TARGET || "lk";

async function bootstrap() {
  if (buildTarget === "lk") {
    await import("./sentry");
  }

  const { default: Environment } = await import(`./entrypoints/${buildTarget}`);

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(<Environment />);
}

bootstrap();
